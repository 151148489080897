<template>
  <a href="#" class="btn" @click="logout">
    Logout
  </a>
</template>

<script>
import firebase from 'firebase/compat/app';

export default {
  setup() {
    const logout = async () => {
      const auth = firebase.auth();
      await auth.signOut();
    };

    return {
      logout,
    };
  },
};
</script>
