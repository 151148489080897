import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { ref } from 'vue';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCGFAKlwGNyYSo8zWqvm_SPGyk0lV5iijQ",
  authDomain: "dailynote-app.firebaseapp.com",
  projectId: "dailynote-app",
  storageBucket: "dailynote-app.appspot.com",
  messagingSenderId: "637625971294",
  appId: "1:637625971294:web:980e9e71126765ecf3facf",
  measurementId: "G-RCTD32B6RQ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//const db = getFirestore();
getFirestore();
getAnalytics();

// 여기에 로그인한 사용자를 담을꺼야.
export const user = ref(null);
export const initialised = ref(false);

const auth = firebase.auth();

// 이건 사용자가 로그인하면 호출된다.
auth.onAuthStateChanged(u => {
  user.value = u;
  initialised.value = true;
});

export async function logout() {
  await auth.signOut();
}

export async function google() {
  await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
}

export function useSignup() {
  // signup 폼에서 사용할 것들 초기화
  const email = ref('');
  const password = ref('');

  // signup 폼에서 호출할 함수 초기화
  async function signup() {
    if (email.value == '' || password.value == '') return;

    const creds = await auth.createUserWithEmailAndPassword(
      email.value,
      password.value
    );

    if (!creds.user) throw Error('Signup failed');

    user.value = creds.user;
  }

  return {
    email,
    password,
    signup,
  };
}

export async function useLogin() {
  const email = ref('');
  const password = ref('');

  const login = async () => {
    const resp = await auth.signInWithEmailAndPassword(
      email.value,
      password.value
    );
    if (!resp.user) throw Error('No user');
    user.value = resp.user;
  };

  return {
    email,
    password,
    login,
  };
}
