import { createWebHistory, createRouter } from 'vue-router';
import { watch } from 'vue';
import Home from '../pages/Home.vue';
import { user, initialised } from '@/hooks/useLogin';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../pages/Login.vue'),
    meta: { public: true },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../pages/Signup.vue'),
    meta: { public: true },
  },
  {
    path: '/protected',
    name: 'Protected',
    component: () => import('../pages/Protected.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _, next) => {
  if (initialised.value) {
    if (!to.matched.some(record => record.meta.public) && !user.value) {
      return next('/login');
    }

    next();
  } else {
    watch(
      () => initialised.value,
      newVal => {
        if (newVal) {
          if (!to.matched.some(record => record.meta.public) && !user.value) {
            return next('/login');
          }

          next();
        }
      }
    );
  }
});

export default router;
