<template>
  <h1>매일 로깅</h1>
  <div id="nav">
    <router-link to="/">Home</router-link>
  </div>
  <router-view />
</template>

<style>
</style>
